import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { Box, Text, Card, Flex, Heading } from 'theme-ui'
import { Layout, Stack, Main, Sidebar, Hero } from '@layout'
import Divider from '@components/Divider'
import Avatar from '@components/Avatar'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import { getStaticPageSeoDetails } from '@utils-blog'
import HeroComponent from '../../../gatsby-theme-flexiblog-personal/src/components/Hero/Hero'
import MemphisPattern from '@components/MemphisPattern/MemphisPattern'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { css } from 'theme-ui'
/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  innerSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  boxs: {
    display: `grid`,
    gridTemplateColumns: 'auto auto',
    gap: '20px',
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'auto'
    },
    '>div >div': {
      height:`100%`
    }
  },
  buttons: {
    m: 1
  },
  wrapper: {
    textAlign: `center`,
    display: 'flex',
    alignItems: 'start'
  },
  avatarWrapper: {
    mb: 4,
    mr: 3,
    borderRadius: 20,
    overflow: 'hidden'
  },
  section: {
    textAlign: 'start',
    maxWidth: '300px'
  },
  title: {
    color: `omegaDark`
  },
  des: {
    textAlign: 'left',
    lineHeight: '1.5'
  },
  pBox: {
    p: 50
  }
}

export default props => {
  let seoData = getStaticPageSeoDetails('shared.about')

  const aboutSeoData = {
    ...seoData,
    About: {
      ...seoData?.About,
      keywords: seoData?.About?.keywords?.split('\n')
    }
  }

  return (
    <Layout {...props}>
      <Seo
        title={aboutSeoData?.About?.metaTitle}
        description={aboutSeoData?.About?.metaDescription}
        keywords={aboutSeoData?.About?.keywords}
        thumbnail={aboutSeoData?.About?.metaImage}
      />
      <Hero full>
        <Hero sx={{ padding: `50px 0` }}>
          <HeroComponent
            customHeroFlag={true}
            heroType={'about'}
            {...props}
            callFrom='About'
          />
        </Hero>
        <MemphisPattern customHeroBgFlag={true} />
      </Hero>
      <Divider />
      <Stack>
        <Main sx={{'display':'flex',flexDirection:'column'}}>
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <div id={'who-we-are'}>
                <Card sx={styles.pBox}>
                  <Section title='Who We Are'>
                    <Text variant='p'>
                      The Commission on the Franciscan Intellectual-Spiritual
                      Tradition (CFIT) was established in 2001 by the
                      English-Speaking Conference of the Order of Friars Minor
                      (OFM) to promote a contemporary retrieval of the
                      distinctive theological and spiritual vision that animates
                      the Franciscan movement.
                    </Text>
                    <Text variant='p'>
                      That vision is rooted in the charism of Francis and Clare
                      of Assisi who initiated a new Gospel way of life within
                      the Church. It was developed in a more systematic manner
                      by such academic theologians as Alexander of Hales,
                      Bonaventure, Roger Bacon, Peter of John Olivi, John Duns
                      Scotus, and William of Ockham, as well as by vernacular
                      theologians such as Angela of Foligno and Veronica
                      Giuliani. Unfortunately, in more recent centuries, the
                      voices of such thinkers have seldom been heard so that the
                      Franciscan tradition has been often relegated to a
                      “minority voice” within the Church.
                    </Text>
                    <Text variant='p'>
                      CFIT is convinced that our Franciscan tradition has a
                      powerful “word” to speak to people today, one that
                      responds to deeply-felt needs in our Church and our world.
                      As Joseph Chinnici, OFM, stated in a report to the
                      English-Speaking Conference of the Franciscan Order in
                      2001:
                    </Text>
                    <Text variant='p'>
                      "When our intellectual tradition, with its view of God’s
                      overflowing goodness, its Christocentric emphasis, its
                      moral decision-making process, its view of a Spirit-filled
                      yet sinful Church, its understanding of property and
                      community, and its valuation of freedom and perfect
                      dignity, is presented to people, it almost always meets
                      with an enthusiastic reception. But do we really know this
                      tradition? Are our resources mobilized so as to protect
                      and update it?"
                    </Text>
                    <Text variant='p'>
                      CFIT was founded in response to the questions he posed.
                      Our mission is to retrieve, preserve, and articulate that
                      tradition in a language that is understandable to
                      contemporary men and women and addresses the issues of our
                      day.
                    </Text>
                  </Section>
                </Card>
              </div>
            </Box>
          </Flex>
          <Divider />
          <Flex sx={styles.grid}>        
            <Box sx={styles.column}>
              <div id='what-we-do'>
                <Card sx={styles.pBox}>
                  <Section title='What We Do'>
                    <Text variant='p'>
                      Over the past decade, CFIT has accomplished a number of
                      its initiatives to preserve the Franciscan tradition.
                    </Text>
                    <ul>
                      <li>
                        Identify the central themes and emphases of the
                        Franciscan Intellectual Tradition. This led us to create
                        a Franciscan Heritage Series, designed to break open
                        those basic themes for non-specialists. So far ten
                        volumes in this series have appeared.
                      </li>
                      <li>
                        For men and women with a more theological background,
                        the Commission also decided to publish the Washington
                        Theological Union Symposium Papers. Beginning in 2001,
                        ten symposia were hosted by the WTU, bringing the
                        insights of Franciscan theology to bear on such
                        contemporary issues as ecclesiology, economics,
                        evangelization, and ecology.
                      </li>
                      <li>
                        A third series of CFIT publications, the{' '}
                        <GLink
                          style={{
                            textDecoration: 'none',
                            color: '#667eea'
                          }}
                          target='_blank'
                          to='https://franciscantradition.org/resources/custodians-of-the-tradition'
                        >
                          Custodians of the Tradition
                        </GLink>{' '}
                        , provide four-page syntheses of important articles by
                        noted scholars on key elements of the Franciscan
                        tradition; these are designed for use in the classroom
                        or in adult education groups.
                      </li>
                      <li>
                        CFIT has also cooperated with the Franciscan Institute
                        of St. Bonaventure University in hosting several study
                        groups for scholars who want to work together to share
                        their research and deepen their insights on a specific
                        aspect of the Franciscan tradition. The result of one of
                        these “think tanks” is the important volume on The
                        Franciscan Moral Vision: Responding to God’s Love,
                        edited by Thomas Nairn, OFM.
                      </li>
                      <li>
                        Many informative presentations have been given over the
                        years by Franciscan scholars, some of which are virtual
                        “classics” in the field. Another initiative, Retrieving
                        the Tradition, makes these presentations accessible to a
                        larger audience by reformatting them in DVDs. Talks are
                        broken down into smaller segments to facilitate their
                        use and are accompanied by study guides.
                      </li>
                      <li>
                        The creation of this website – The Franciscan
                        Intellectual Tradition – to serve as an entry portal for
                        people engaged in Franciscan studies or interested in
                        learning more about our tradition.
                      </li>
                      <li>
                        Making the foundational documents of our tradition – the
                        writings of Francis and Clare of Assisi, as well as
                        their early biographies – instantly accessible
                        worldwide. CFIT is currently uploading all of the early
                        Franciscan sources to our website: both the original
                        Latin texts as well as their English translation found
                        in the three-volume series, Francis of Assisi: Early
                        Documents (FA:ED), edited by Regis Armstrong, OFM Cap.,
                        Wayne Hellmann, OFM Conv., and William J Short, OFM,
                        published between 1999-2001, and in Clare of Assisi:
                        Early Documents (CA:ED), edited by Regis Armstrong, OFM
                        Cap., and published in 2006.
                      </li>
                    </ul>
                    <Text variant='p'>
                      To complement this website, CFIT has also launched{' '}
                      <GLink
                        style={{
                          textDecoration: 'none',
                          color: '#667eea'
                        }}
                        target='_blank'
                        to='https://www.facebook.com/franciscantradition'
                      >
                        Facebook
                      </GLink>{' '}
                      and{' '}
                      <GLink
                        style={{
                          textDecoration: 'none',
                          color: '#667eea'
                        }}
                        target='_blank'
                        to='https://twitter.com/FranciscanTrad'
                      >
                        Twitter
                      </GLink>{' '}
                      pages, where we post reflections on major Franciscan
                      liturgical celebrations and events and keep followers
                      abreast of new developments in Franciscan studies.
                    </Text>
                  </Section>
                </Card>
              </div>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

